import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import Cert1 from "../assets//certificates/1.jpg";
import Cert2 from "../assets//certificates/2.jpg";
import Cert3 from "../assets//certificates/3.jpg";
import Cert4 from "../assets/certificates/4.jpg";
import Cert5 from "../assets/certificates/5.jpg";
import Cert6 from "../assets/certificates/6.jpg";
import Cert7 from "../assets/certificates/7.jpg";
import Cert8 from "../assets/certificates/8.jpg";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const images = [Cert1, Cert2, Cert3, Cert4, Cert5, Cert6, Cert7, Cert8];

const Slider2 = () => {
  return (
    <AutoplaySlider className="slider2" play={true} interval={5500}>
      {images.map((img, index) => {
        return (
          <div key={index} className="div">
            <img alt="Certificates" src={img} />
          </div>
        );
      })}
    </AutoplaySlider>
  );
};
export default Slider2;
