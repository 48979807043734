import styles from "./Footer.module.scss";
import Logo from "../../assets/logo_light.svg";
import Line from "../../assets/line_foot.svg";
import Insta from "../../assets/ins.svg";
import Face from "../../assets/face.svg";
import Telega from "../../assets/telegram-1.svg";
import Whats from "../../assets/whats.svg";
import Map from "../../assets/map.svg";

const Footer = () => {
  return (
    <footer className={styles.root}>
      <div className={styles.map}>
        <div>
          <a
            href="https://maps.app.goo.gl/zS9mLDXMRNvfQuRM6"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="Map" src={Map}></img>
            <p>The Palm Jumeirah, Golden Mile Galleria, Dubai</p>
          </a>
        </div>
      </div>
      <div className={styles.logo_block}>
        <img className={styles.line} alt="Line" src={Line} />
        <img className={styles.logo} alt="Palme" src={Logo} />
        <img className={styles.line} alt="Line" src={Line} />
      </div>
      <div className={styles.copy}>
        <p className={styles.side}>Made by SiDe Sys.</p>
        <div className={styles.map_hidden}>
          <a
            href="https://maps.app.goo.gl/zS9mLDXMRNvfQuRM6"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="Map" src={Map}></img>
            <p>The Palm Jumeirah, Golden Mile Galleria, Dubai</p>
          </a>
        </div>
        <p>© 2023 Palme Home Spa. All rights reserved. </p>
        <div className={styles.icons}>
          <a
            href="https://www.instagram.com/palme_spa/"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="Instagram" src={Insta} />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61552324643714"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="Facebook" src={Face} />
          </a>
          <a
            href="https://wa.me/+971552439184"
            target="_blank"
            rel="noreferrer"
          >
            <img alt="WhatsUp" src={Whats} />
          </a>
          <a href="https://t.me/Palmespa" target="_blank" rel="noreferrer">
            <img alt="Telegram" className={styles.telega} src={Telega} />
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
