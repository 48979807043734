import icon from "../assets/whatsapp-icon-1.png";

const WhatsUp = () => {
  return (
    <a href="https://wa.me/+971552439184" target="_blank" rel="noreferrer">
      <img alt="WhatsUp" className="whatsUp-icon" src={icon} />
    </a>
  );
};
export default WhatsUp;
