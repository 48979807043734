import AnchorLink from "react-anchor-link-smooth-scroll";
import styles from "./Header.module.scss";
import Logo from "../../assets/logo.png";
import LogoLight from "../../assets/logo-light.png";
import Burger from "../../assets/menu-icon-1.png";
import BurgerLight from "../../assets/menu-icon-1-light.png";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { useState } from "react";
import { links } from "../../store/links";
import useScroll from "../../hooks/useScroll";

const Header = ({ i18n, t }) => {
  const [clicked, setClicked] = useState(false);
  const { isScrolled } = useScroll();
  const handleClick = () => {
    setClicked(!clicked);
  };
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    language === "en"
      ? document.querySelector("#en").classList.add(styles.active_lang)
      : document.querySelector("#en").classList.remove(styles.active_lang);
    language === "ru"
      ? document.querySelector("#ru").classList.add(styles.active_lang)
      : document.querySelector("#ru").classList.remove(styles.active_lang);
  };
  return (
    <header className={`${styles.root} ${isScrolled ? styles.dark : ""}`}>
      <img alt="Palme" src={isScrolled ? Logo : LogoLight} />
      <nav className={styles.navigation}>
        {links.map((link) => {
          return (
            <AnchorLink key={link.href} href={link.href}>
              {t(`${link.title}`)}
            </AnchorLink>
          );
        })}
      </nav>
      <img
        onClick={handleClick}
        alt="Burger menu"
        src={isScrolled ? Burger : BurgerLight}
        className={`${styles.burger} ${clicked ? styles.rotated : ""}`}
      />
      <div className={styles.language}>
        <a
          className={styles.active_lang}
          id="en"
          href="#en"
          onClick={() => changeLanguage("en")}
        >
          Eng
        </a>
        <span>|</span>
        <a id="ru" href="#ru" onClick={() => changeLanguage("ru")}>
          Rus
        </a>
      </div>
      <BurgerMenu clicked={clicked} setClicked={setClicked} t={t}></BurgerMenu>
    </header>
  );
};
export default Header;
