import Image1 from "../assets/products/1.avif";
import Back1 from "../assets/products/1-background.avif";
import Image2 from "../assets/products/2.avif";
import Back2 from "../assets/products/2-background.avif";
import Image5 from "../assets/products/5.avif";
import Back5 from "../assets/products/5-background.avif";
import Image3 from "../assets/products/3.avif";
import Image4 from "../assets/products/4.avif";

export const info = [
  {
    text: "prod_p1",
    image: Image1,
    background: Back1,
  },
  {
    text: "prod_p2",
    image: Image2,
    background: Back2,
  },
  {
    text: "prod_p3",
    image: Image3,
    background: Back1,
  },
  {
    text: "prod_p4",
    image: Image4,
    background: Back2,
  },
  {
    text: "prod_p5",
    image: Image5,
    background: Back5,
  },
];
