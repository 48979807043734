import styles from "./BurgerMenu.module.scss";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { links } from "../../store/links";
import useScroll from "../../hooks/useScroll";

const BurgerMenu = ({ clicked, setClicked, t }) => {
  const { isScrolled } = useScroll();
  return (
    <article
      id="burger"
      className={`${styles.root} ${isScrolled ? styles.root_dark : ""} ${
        clicked ? styles.showBurger : ""
      }`}
    >
      <nav className={styles.nav}>
        {links.map((link) => {
          return (
            <AnchorLink
              key={link.href}
              href={link.href}
              onClick={() => setClicked(false)}
            >
              {t(`${link.title}`)}
            </AnchorLink>
          );
        })}
      </nav>
    </article>
  );
};
export default BurgerMenu;
