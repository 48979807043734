export const links = [
  {
    title: "link_home",
    href: "#welcome",
  },
  {
    title: "link_serv",
    href: "#services",
  },
  {
    title: "link_prod",
    href: "#products",
  },
  {
    title: "link_cert",
    href: "#certificates",
  },
  {
    title: "link_contact",
    href: "#contact",
  },
];
