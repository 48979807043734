import styles from "./Certificates.module.scss";
import Line from "../../assets/Line 1.svg";
import Icon from "../../assets/certificate-icon 1.svg";
import useAnimate from "../../hooks/useAnimation";
import Slider2 from "../Slider2";

const Certificates = ({ t }) => {
  const { ref, isAnimated } = useAnimate();
  return (
    <section className={styles.root} id="certificates">
      <div
        ref={ref}
        className={`${styles.h_block} animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <h1>{t("certificates_h")}</h1>
        <img src={Line} alt="Line" />
      </div>
      <article
        ref={ref}
        className={`${styles.row} animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <div className={styles.img_block}>
          <Slider2 />
        </div>
        <div className={styles.text_block}>
          <h3>{t("certificates_h")}</h3>
          <div>
            <img
              className={styles.icon}
              alt="Certificate icon"
              src={Icon}
            ></img>
            <img className={styles.line} alt="Line" src={Line}></img>
          </div>
          <p>{t("certificates_p")}</p>
        </div>
      </article>
    </section>
  );
};
export default Certificates;
