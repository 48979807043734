import styles from "./Contact.module.scss";
import Mail from "../../assets/Rectangle 29.svg";
import Phone from "../../assets/Rectangle 29 (1).svg";
import Whats from "../../assets/Rectangle 29 (2).svg";
import Telega from "../../assets/telegram-2.svg";
import Inst from "../../assets/instagram-icon.png";
import Face from "../../assets/facebook-icon.png";

import { sendFormToTelegram } from "../../utils/postTelegram";
import useAnimate from "../../hooks/useAnimation";

const sendInfo = (e) => {
  const name = document.querySelector("#nameInput");
  const phone = document.querySelector("#phoneInput");
  const text = document.querySelector("#textInput");
  const string = `| Palme Home Spa | \nName: ${name.value} \nPhone number: ${phone.value} \nMessage: ${text.value}`;
  e.preventDefault();
  if (name.value.trim() === "" && phone.value.trim() === "") {
    alert("Fill in all required fields!");
    return;
  }
  sendFormToTelegram(string);
  name.value = "";
  phone.value = "";
  text.value = "";
};

const Contact = ({ t }) => {
  const { ref, isAnimated } = useAnimate();
  return (
    <section className={styles.root} id="contact">
      <article
        ref={ref}
        className={`${styles.form_block} animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <h1>{t("contact_h")}</h1>
        <div
          ref={ref}
          className={`${styles.form_row} animated-element ${
            isAnimated ? "animate" : ""
          }`}
        >
          <form className={styles.form} onSubmit={(e) => sendInfo(e)}>
            <input placeholder={t("contact_name")} required id="nameInput" />
            <input placeholder={t("contact_phone")} required id="phoneInput" />
            <textarea
              type="text"
              placeholder={t("contact_text")}
              id="textInput"
              className={styles.text}
            ></textarea>
            <button type="submit">{t("contact_btn")}</button>
          </form>
          <h3>{t("contact_or")}</h3>
          <div className={styles.contact_block}>
            <h5>{t("contact_info")}</h5>

            <a href="mailto:Palmespa@gmail.com" className={styles.div}>
              <img alt="Mail" src={Mail} />
              <p>Palmespa@gmail.com</p>
            </a>
            <a href="tel:+971552439184" className={styles.div}>
              <img alt="Phone" src={Phone} />
              <p>+971 (55) 2439184</p>
            </a>
            <a
              href="https://wa.me/+971552439184"
              target="_blank"
              rel="noreferrer"
              className={styles.div}
            >
              <img alt="WhatsUp" src={Whats} />
              <p>+971 (55) 2439184</p>
            </a>
            <a
              className={styles.telega_div}
              href="https://t.me/Palmespa"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Telegram" className={styles.telega} src={Telega} />
              <p>Telegram</p>
            </a>
            <a
              className={styles.telega_div}
              href="https://www.instagram.com/palme_spa/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Instagram" className={styles.telega} src={Inst} />
              <p>Instagram</p>
            </a>
            <a
              className={styles.telega_div}
              href="https://www.facebook.com/profile.php?id=61552324643714"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Facebook" className={styles.telega} src={Face} />
              <p>Facebook</p>
            </a>
          </div>
        </div>
      </article>
    </section>
  );
};
export default Contact;
