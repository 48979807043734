import styles from "./Products.module.scss";
import Line from "../../assets/Line 1.svg";
import Line2 from "../../assets/line 2.svg";
import Arrow1 from "../../assets/arrow-button 1.svg";
import Arrow2 from "../../assets/arrow-button 2.svg";
import { useRef, useState, useEffect } from "react";
import useAnimate from "../../hooks/useAnimation";
import Marquee from "react-fast-marquee";
import { info } from "../../store/products";

const Products = ({ t }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const refText = useRef(null);
  const refImage = useRef(null);
  const refBack = useRef(null);
  const { ref, isAnimated } = useAnimate();
  useEffect(() => {
    info.forEach((item) => {
      const img = new Image();
      img.src = item.image;
      const img1 = new Image();
      img1.src = item.background;
    });
  }, []);
  const handleArrowClick = (direction) => {
    direction === "prev"
      ? setSelectedIndex((prevIndex) =>
          prevIndex === 0 ? info.length - 1 : prevIndex - 1
        )
      : setSelectedIndex((prevIndex) =>
          prevIndex === info.length - 1 ? 0 : prevIndex + 1
        );
    refText.current.classList.add(styles.animated);
    refImage.current.classList.add(styles.animated);
    refBack.current.classList.add(styles.animated);
    setTimeout(() => {
      refText.current.classList.remove(styles.animated);
      refImage.current.classList.remove(styles.animated);
      refBack.current.classList.remove(styles.animated);
    }, 1100);
  };

  const selectedInfo = info[selectedIndex];
  return (
    <section className={styles.root} id="products">
      <div
        ref={ref}
        className={`${styles.h_block} animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <h1>{t("products_h")}</h1>
        <img alt="Line" src={Line} />
      </div>
      <article
        className={styles.slider_block}
        style={{ backgroundImage: `url(${selectedInfo.background})` }}
        ref={refBack}
      >
        <Marquee direction="right" className={styles.line2}>
          <img ref={ref} className={styles.line2_img} alt="Line2" src={Line2} />
        </Marquee>
        <div className={styles.wrap_div} ref={refImage}>
          <img
            className={styles.arrow}
            id="arrow1"
            alt="Arrow"
            src={Arrow1}
            onClick={() => handleArrowClick("prev")}
          />
          <img
            alt="Mask"
            src={selectedInfo.image}
            className={styles.mainImage}
          />
          <img
            className={styles.arrow}
            alt="Arrow"
            id="arrow2"
            src={Arrow2}
            onClick={() => handleArrowClick("next")}
          />
        </div>
        <div className={styles.text_block} ref={refText}>
          {t(`${selectedInfo.text}`)}
        </div>
        <Marquee direction="left" className={styles.line2_2}>
          <img ref={ref} className={styles.line2_img} alt="Line2" src={Line2} />
        </Marquee>
      </article>
    </section>
  );
};
export default Products;
