import facial1 from "../assets/facial/1.jpg";
import facial2 from "../assets/facial/2.jpg";
import facial3 from "../assets/facial/3.jpg";
import facial4 from "../assets/facial/4.jpg";
import facial5 from "../assets/facial/5.jpg";

import massage1 from "../assets/massage/1.jpg";
import massage2 from "../assets/massage/2.jpg";
import massage3 from "../assets/massage/3.jpg";
import massage4 from "../assets/massage/4.jpg";
import massage5 from "../assets/massage/5.jpg";
import massage6 from "../assets/massage/6.jpg";

export const infoFacial = [
  {
    heading: "serv_face_h_1",
    description: "serv_face_p_1",
    image: facial1,
  },
  {
    heading: "serv_face_h_2",
    description: "serv_face_p_2",
    image: facial2,
  },
  {
    heading: "serv_face_h_3",
    description: "serv_face_p_3",
    image: facial3,
  },
  {
    heading: "serv_face_h_4",
    description: "serv_face_p_4",
    image: facial4,
  },
  {
    heading: "serv_face_h_5",
    description: "serv_face_p_5",
    image: facial5,
  },
];

export const infoMassage = [
  {
    heading: "serv_mas_h_1",
    description: "serv_mas_p_1",
    image: massage1,
  },
  {
    heading: "serv_mas_h_2",
    description: "serv_mas_p_2",
    image: massage2,
  },
  {
    heading: "serv_mas_h_3",
    description: "serv_mas_p_3",
    image: massage3,
  },
  {
    heading: "serv_mas_h_4",
    description: "serv_mas_p_4",
    image: massage4,
  },
  {
    heading: "serv_mas_h_5",
    description: "serv_mas_p_5",
    image: massage5,
  },
  {
    heading: "serv_mas_h_6",
    description: "serv_mas_p_6",
    image: massage6,
  },
];
