import styles from "./Welcome.module.scss";
import Logo from "../../assets/logo-light.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useAnimate from "../../hooks/useAnimation";

const Welcome = ({ t }) => {
  const { ref, isAnimated } = useAnimate();
  return (
    <section className={styles.root} id="welcome">
      <article
        ref={ref}
        className={`${styles.welcome_row} animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <div className={styles.row_text}>
          <img alt="Palme logo" src={Logo}></img>
          <h1>BEYOND YOUR EXPECTATION</h1>
          <p>{t("welcome_p")}</p>
          <AnchorLink href="#contact">
            <button className={styles.button}>{t("welcome_btn")}</button>
          </AnchorLink>
        </div>
      </article>
    </section>
  );
};
export default Welcome;
