import styles from "./Services.module.scss";
import { useRef, useState, useEffect } from "react";
import useAnimate from "../../hooks/useAnimation";
import Line from "../../assets/Line 1.svg";
import Arrow1 from "../../assets/arrow-button 1.svg";
import Arrow2 from "../../assets/arrow-button 2.svg";
import Arrow1_1 from "../../assets/arrow-button-balck 1.svg";
import Arrow2_2 from "../../assets/arrow-button-black 2.svg";
import { infoFacial, infoMassage } from "../../store/services";

const Services = ({ t }) => {
  const [selectedFacialIndex, setSelectedFacialIndex] = useState(0);
  const [selectedMassageIndex, setSelectedMassageIndex] = useState(0);
  const refText1 = useRef(null);
  const refText2 = useRef(null);
  const refImage1 = useRef(null);
  const refImage2 = useRef(null);
  useEffect(() => {
    infoFacial.forEach((item) => {
      const img = new Image();
      img.src = item.image;
    });
    infoMassage.forEach((item) => {
      const img = new Image();
      img.src = item.image;
    });
  }, []);
  const handleArrowClick = (direction, setState, info, ref, refImage) => {
    direction === "prev"
      ? setState((prevIndex) =>
          prevIndex === 0 ? info.length - 1 : prevIndex - 1
        )
      : setState((prevIndex) =>
          prevIndex === info.length - 1 ? 0 : prevIndex + 1
        );
    ref.current.classList.add(styles.animated);
    refImage.current.classList.add(styles.animated);
    setTimeout(() => {
      ref.current.classList.remove(styles.animated);
      refImage.current.classList.remove(styles.animated);
    }, 1100);
  };

  const selectedFacial = infoFacial[selectedFacialIndex];
  const selectedMassage = infoMassage[selectedMassageIndex];
  const { ref, isAnimated } = useAnimate();
  return (
    <section className={styles.root} id="services">
      <div
        ref={ref}
        className={`${styles.h_block} animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <h1>{t("services_h")}</h1>
        <img alt="Line" src={Line} />
      </div>
      <article
        ref={ref}
        className={`${styles.article} animated-element ${
          isAnimated ? "animate" : ""
        }`}
      >
        <div className={styles.article_row}>
          <div className={styles.text_block}>
            <div className={styles.text_block_wrap}>
              <h1>{t("services_facial_h")}</h1>
              <h5>{t("duration_facial")}</h5>
              <h5>{t("price_facial")}</h5>
            </div>
            <div className={styles.wrapper} ref={refText1}>
              <h5>{t(`${selectedFacial.heading}`)}</h5>
              <p>{t(`${selectedFacial.description}`)}</p>
            </div>
            <div className={styles.arrow_block}>
              <img
                alt="Arrow prev"
                src={Arrow1}
                onClick={() =>
                  handleArrowClick(
                    "prev",
                    setSelectedFacialIndex,
                    infoFacial,
                    refText1,
                    refImage1
                  )
                }
              ></img>
              <img
                alt="Arrow next"
                src={Arrow2}
                onClick={() =>
                  handleArrowClick(
                    "next",
                    setSelectedFacialIndex,
                    infoFacial,
                    refText1,
                    refImage1
                  )
                }
              ></img>
            </div>
          </div>
          <div ref={refImage1} className={styles.slider_image}>
            <img alt="Facial" src={selectedFacial.image} />
          </div>
        </div>
        <div className={styles.article_row}>
          <div ref={refImage2} className={styles.slider_image}>
            <img alt="Massage" src={selectedMassage.image} />
          </div>
          <div className={`${styles.text_block} ${styles.text_block2}`}>
            <div className={styles.text_block_wrap}>
              <h1>{t("services_massage_h")}</h1>
              <h5>{t("duration_massage")}</h5>
              <h5>{t("price_massage")}</h5>
            </div>
            <div className={styles.wrapper} ref={refText2}>
              <h5>{t(`${selectedMassage.heading}`)}</h5>
              <p>{t(`${selectedMassage.description}`)}</p>
            </div>
            <div className={styles.arrow_block}>
              <img
                alt="Arrow prev"
                src={Arrow1_1}
                onClick={() =>
                  handleArrowClick(
                    "prev",
                    setSelectedMassageIndex,
                    infoMassage,
                    refText2,
                    refImage2
                  )
                }
              ></img>
              <img
                alt="Arrow next"
                src={Arrow2_2}
                onClick={() =>
                  handleArrowClick(
                    "next",
                    setSelectedMassageIndex,
                    infoMassage,
                    refText2,
                    refImage2
                  )
                }
              ></img>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};
export default Services;
