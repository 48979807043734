export async function sendFormToTelegram(message) {
  const botToken = "6584140713:AAHfqSt5QsObPpKHjGvh5KWX2K9SK0LiHtU";
  const chatId = "-4002361888";
  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: chatId,
        text: message,
      }),
    });
    if (response.ok) {
      alert(
        "The form has been sent successfully! The operator will contact you."
      );
    } else {
      throw new Error("Failed to submit form");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  }
}
