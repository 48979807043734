import "../styles/App.scss";
import { useTranslation } from "react-i18next";
import Certificates from "./Certificates/Certificates";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Loader from "./Loader";
import Products from "./Products/Products";
import Services from "./Services/Services";
import Welcome from "./Welcome/Welcome";
import WhatsUp from "./WhatsUp";

window.onload = function () {
  document.body.classList.add("loaded_hiding");
  window.setTimeout(function () {
    document.body.classList.add("loaded");
    document.body.classList.remove("loaded_hiding");
  }, 500);
};

const App = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="App">
      <Header t={t} i18n={i18n} />
      <Welcome t={t} />
      <Services t={t} />
      <Products t={t} />
      <Certificates t={t} />
      <Contact t={t} />
      <Footer t={t} />
      <Loader />
      <WhatsUp />
    </div>
  );
};
export default App;
